<!-- 提现记录 -->
<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="nav-page-container">
      <!-- 下拉刷新 -->
      <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          style="position: relative;z-index: 1"
      >
        <template #loading>
          {{ pagination.pageNum < 2 ? '' : '加载中...' }}
        </template>

        <!-- 更多列表加载 -->
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedTextRender()"
            @load="onLoad"
        >
          <!-- 提现记录 -->
          <div class="recordTitle">
            <span>提现记录：</span>
          </div>
          <div
              v-for="item of dataList"
              class="recordBox"
          >
            <div class="priceRecord">
              <span>提现单号：</span>
              <span>{{item.orderNumber}}</span>
            </div>
            <div class="priceRecord">
              <span>提现金额：{{ item.amount }}</span>
              <span>{{ item.status == 1 ? '申请中' : item.status == 2 ? '提现成功' : item.status == 3 ? '提现失败' : '' }}</span>
            </div>
            <div class="priceRecord">
              <span>提现时间：{{ item.createTime }}</span>
              <span v-if="item.status==2">到账时间：{{ item.createTime }}</span>
            </div>

            <div class="priceRecord" v-if="item.status == 3">
              <span>提现失败原因：{{ item.failReason }}</span>
            </div>

          </div>
        </van-list>
      </van-pull-refresh>
    </page-container>
  </div>
</template>

<script>
import { getCashOutRecordByPage } from '@/api/withdrawal';

export default {
  name: 'withdrawRecord',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    onLoad() {
      this.pagination.pageNum++;
      let {
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getCashOutRecordByPage({
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    finishedTextRender() {
      let { loading, finished, dataList } = this;
      if (!loading && finished) {
        return dataList.length ? '没有更多了' : '暂无相关数据';
      }
    },

  },
};
</script>

<style lang="less" scoped>
.recordTitle {
  width: 93%;
  margin: 15px auto;

  span {
    font-size: 15px;
    font-weight: 600;
  }
}

.recordBox {
  margin: 0 16px;
  padding: 16px 0;
  border-bottom: 1px solid #dedede;

  .priceRecord {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    text {
      font-size: 12px;
    }
  }
}

</style>
